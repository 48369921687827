<template>
    <div>
      <el-form :model="filters" inline>
        <el-form-item label="Class">
          <el-select v-model="filters.class_id" placeholder="Select Class" @change="fetchSections">
            <el-option v-for="classItem in classes" :key="classItem.id" :label="classItem.name" :value="classItem.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Section">
          <el-select v-model="filters.section_id" placeholder="Select Section" @change="fetchStudents">
            <el-option v-for="section in sections" :key="section.id" :label="section.name" :value="section.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchStudents">Filter Students</el-button>
        </el-form-item>
      </el-form>
  
      <el-table :data="students" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" />
        <el-table-column property="name" label="Name" width="120" />
        <el-table-column property="class.name" label="Class" width="120" />
        <el-table-column property="section.name" label="Section" width="120" />
        <el-table-column property="id" label="ID" width="120" />
      </el-table>
  
      <div class="step-actions">
        <el-button type="success" @click="generatePDF">Generate PDF</el-button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import logoWater from '@/assets/img/logo-school-one.png';
  import placeholderImage from '@/assets/img/placeholder-student.png'; // Add placeholder image path
  
  const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
  });
  
  export default {
    name: 'CardsStudents',
    data() {
      return {
        filters: {
          class_id: '',
          section_id: ''
        },
        classes: [],
        sections: [],
        students: [],
        selectedStudents: []
      };
    },
    methods: {
      async fetchClasses() {
        try {
          const response = await api.get('/classes');
          this.classes = response.data;
        } catch (error) {
          this.$message.error('Failed to fetch classes');
        }
      },
      async fetchSections() {
        try {
          const response = await api.get('/sections', {
            params: { class_id: this.filters.class_id }
          });
          this.sections = response.data;
        } catch (error) {
          this.$message.error('Failed to fetch sections');
        }
      },
      async fetchStudents() {
        try {
          const response = await api.get('/students-by-class-and-section-filtering', {
            params: {
              class_id: this.filters.class_id,
              section_id: this.filters.section_id
            }
          });
          this.students = response.data.data;
        } catch (error) {
          this.$message.error('Failed to fetch students');
        }
      },
      handleSelectionChange(val) {
        this.selectedStudents = val;
      },
      generatePDF() {
  const studentCards = this.selectedStudents.map(student => `
    <div class="card">
      <div class="watermark">
        <img src="${logoWater}" alt="School Logo" />
      </div>
      <div class="card-content">
        <img src="${placeholderImage}" alt="Student Photo" class="student-photo" />
        <h2>Almasa School</h2>
        <p>Name: ${student.name}</p>
        <p>Class: ${student.class ? student.class.name : 'N/A'}</p>
        <p>Section: ${student.section ? student.section.name : 'N/A'}</p>
        <p>Number: ${student.id}</p>
        <img src="http://api.qrserver.com/v1/create-qr-code/?data=Student%20Number:%20${student.id}&size=80x80" alt="QR Code" class="qr-code">
      </div>
    </div>
  `).join('');

  const pdfContent = `
    <html>
    <head>
      <style>
        body { font-family: Arial, sans-serif; margin: 20px; display: flex; flex-wrap: wrap; }
        .card { position: relative; width: 350px; height: 200px; border: 1px solid #000; margin: 20px; padding: 10px; box-sizing: border-box; display: flex; flex-direction: row; align-items: center; }
        .watermark { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 0.1; width: 200px; height: 200px; }
        .watermark img { width: 100%; height: 100%; }
        .card-content { position: relative; z-index: 1; text-align: left; flex: 1; padding-left: 10px; }
        .card-content h2 { margin: 0 0 10px; text-align: center; }
        .card-content p { margin: 5px 0; }
        .card-content img.student-photo { float: left; margin-right: 10px; width: 60px; height: 60px; border-radius: 50%; }
        .card-content img.qr-code { position: absolute; bottom: 10px; right: 10px; width: 80px; height: 80px; }
      </style>
    </head>
    <body>
      ${studentCards}
    </body>
    </html>
  `;

  const pdfWindow = window.open('', '', 'height=800,width=600');
  pdfWindow.document.write(pdfContent);
  pdfWindow.document.close();
  pdfWindow.print();
}

    },
    mounted() {
      this.fetchClasses();
    }
  };
  </script>
  
  <style scoped>
  .card {
    display: inline-block;
    vertical-align: top;
  }
  </style>
  