<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-12">
        <div class="card my-4">
          <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
            <div class="d-flex justify-content-around">
              <h6 class="text-white text-capitalize ps-3">Manage Subscription Fees</h6>
              <el-button @click="fetchSubscriptionFees" type="success" circle class="mr-5">
                <el-icon style="vertical-align: middle">
                  <Refresh />
                </el-icon>
              </el-button>
            </div>
          </div>
          <div>
            <el-table :data="subscriptionFees" style="width: 100%">
              <el-table-column prop="category" label="Category" width="200">
                <template #default="scope">
                  <el-input v-model="scope.row.category" size="mini" @blur="updateSubscriptionFee(scope.row)"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="sub_category" label="Sub Category" width="200">
                <template #default="scope">
                  <el-input v-model="scope.row.sub_category" size="mini" @blur="updateSubscriptionFee(scope.row)"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="amount" label="Amount" width="120">
                <template #default="scope">
                  <el-input v-model.number="scope.row.amount" type="number" size="mini" @blur="updateSubscriptionFee(scope.row)"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Actions" width="150">
                <template #default="scope">
                  <el-button @click="deleteSubscriptionFee(scope.row.id)" type="danger" size="mini">Delete</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="p-4">
            <el-form :model="newFee" ref="newFeeForm">
              <el-form-item label="Category" prop="category">
                <el-input v-model="newFee.category" placeholder="Enter category" />
              </el-form-item>
              <el-form-item label="Sub Category">
                <el-input v-model="newFee.sub_category" placeholder="Enter sub category" />
              </el-form-item>
              <el-form-item label="Amount" prop="amount">
                <el-input v-model.number="newFee.amount" type="number" placeholder="Enter amount" />
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="createSubscriptionFee">Add Subscription Fee</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Refresh } from '@element-plus/icons-vue';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
});

export default {
  name: 'ManageSubscriptionFees',
  components: {
    Refresh,
  },
  data() {
    return {
      subscriptionFees: [],
      newFee: {
        category: '',
        sub_category: '',
        amount: 0,
      },
    };
  },
  methods: {
    async fetchSubscriptionFees() {
      try {
        const response = await api.get('/subscription-fees');
        this.subscriptionFees = response.data;
      } catch (error) {
        this.$message.error('Failed to fetch subscription fees');
      }
    },
    async updateSubscriptionFee(subscriptionFee) {
      try {
        await api.put(`/subscription-fees/${subscriptionFee.id}`, {
          category: subscriptionFee.category,
          sub_category: subscriptionFee.sub_category,
          amount: subscriptionFee.amount
        });
        this.$message.success('Subscription fee updated successfully');
      } catch (error) {
        this.$message.error('Failed to update subscription fee');
      }
    },
    async deleteSubscriptionFee(id) {
      try {
        await api.delete(`/subscription-fees/${id}`);
        this.subscriptionFees = this.subscriptionFees.filter(fee => fee.id !== id);
        this.$message.success('Subscription fee deleted successfully');
      } catch (error) {
        this.$message.error('Failed to delete subscription fee');
      }
    },
    async createSubscriptionFee() {
      try {
        await api.post('/subscription-fees', this.newFee);
        this.$message.success('Subscription fee created successfully');
        this.newFee = { category: '', sub_category: '', amount: 0 }; // Reset form
        this.fetchSubscriptionFees(); // Refresh list
      } catch (error) {
        this.$message.error('Failed to create subscription fee');
      }
    }
  },
  mounted() {
    this.fetchSubscriptionFees();
  }
};
</script>

<style scoped>
.el-form {
  margin-bottom: 20px;
}
</style>
