<template>
    <div class="container-fluid py-4">
        <div class="row">
            <div class="col-12">
                <div class="card my-4">
                    <div class="bg-header-table shadow-success border-radius-lg pt-4 pb-3">
                        <div class="d-flex justify-content-between align-items-center">
                            <h6 class="text-white text-capitalize ps-3 mb-0">Manage Attendances</h6>
                            <div class="d-flex align-items-center">
                                <!-- Dropdown for selecting the date -->
                                <el-select v-model="selectedDate" @change="fetchAttendances" placeholder="Select date" class="mr-3" size="small">
                                    <el-option v-for="date in availableDates" :key="date" :label="date" :value="date"></el-option>
                                </el-select>
                                <el-button @click="dialogVisible = true" type="success" circle class="mr-2">
                                    <el-icon size="20" style="vertical-align: middle;">
                                        <Document />
                                    </el-icon>
                                </el-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table :data="attendances" style="width: 100%">
                            <el-table-column prop="date" label="Date" />
                            <el-table-column prop="class.name" label="Class" />
                            <el-table-column prop="section.name" label="Section" />
                            <el-table-column label="Operations" width="150">
                                <template #default="scope">
                                    <el-button @click="viewAttendance(scope.row)" type="primary" size="mini">View</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog v-model="dialogVisible" title="Add Attendance" width="800">
            <el-form :model="form" ref="attendanceForm">
                <el-form-item label="Date" :rules="[{ required: true, message: 'Please select a date', trigger: 'change' }]">
                    <el-date-picker
                        v-model="form.date"
                        type="date"
                        @change="handleDateChange"
                        placeholder="Pick a date"
                        size="small">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="Class" :rules="[{ required: true, message: 'Please select a class', trigger: 'change' }]">
                    <el-select v-model="form.class_id" @change="fetchSections" placeholder="Select class" size="small">
                        <el-option v-for="classItem in classes" :key="classItem.id" :label="classItem.name" :value="classItem.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Section" :rules="[{ required: true, message: 'Please select a section', trigger: 'change' }]">
                    <el-select v-model="form.section_id" @change="fetchStudents" placeholder="Select section" size="small">
                        <el-option v-for="section in sections" :key="section.id" :label="section.name" :value="section.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="Students">
                    <el-table :data="form.students" style="width: 100%">
                        <el-table-column prop="name" label="Student Name" />
                        <el-table-column label="Attendance">
                            <template #default="scope">
                                <el-select v-model="scope.row.attendance" placeholder="Select Attendance" size="small">
                                    <el-option v-for="option in attendanceOptions" :key="option.value" :label="option.label" :value="option.label"></el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
            <div class="step-actions">
                <el-button type="primary" @click="submitForm">Submit</el-button>
            </div>
        </el-dialog>

        <el-dialog v-model="dialogViewAttendance" title="View Attendance" width="800">
            <div>
                <el-table :data="selectedAttendance" style="width: 100%">
                    <el-table-column prop="student.name" label="Student Name" />
                    <el-table-column prop="status" label="Attendance" />
                </el-table>
                <el-button @click="printAttendance" type="primary" icon="el-icon-printer" style="margin-top: 10px;">
                    Print
                </el-button>
            </div>
        </el-dialog>
        
    </div>
</template>


<script>
import { Document } from '@element-plus/icons-vue';
import axios from 'axios';
import logo from "@/assets/img/logo-school.png";
import logoWater from "@/assets/img/logo-school-one.png";
const api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
});

export default {
    name: 'ManageAttendances',
    components: {
        Document,
    },
    data() {
        return {
            dialogVisible: false,
            dialogViewAttendance: false,
            form: {
                date: '',
                class_id: null,
                section_id: null,
                students: [],
            },
            classes: [],
            sections: [],
            attendances: [],
            selectedAttendance: [],
            attendanceOptions: [
                { label: 'Present', value: 'Present' },
                { label: 'Absent', value: 'Absent' },
                { label: 'Half day', value: 'Half day' },
                { label: 'Late', value: 'Late' },
                { label: 'Has permission - Circumstance', value: 'Has permission - Circumstance' },
            ],
            availableDates: [], // Dates for the dropdown
            selectedDate: '', // Selected date for the dropdown
        };
    },
    methods: {
        formatDate(date) {
            const d = new Date(date);
            return d.toISOString().split('T')[0]; // This returns the date in 'YYYY-MM-DD' format
        },
        adjustDateToLocalTime(date) {
            const adjustedDate = new Date(date);
            adjustedDate.setMinutes(adjustedDate.getMinutes() + adjustedDate.getTimezoneOffset());
            return adjustedDate.toISOString().split('T')[0];
        },
        handleDateChange(value) {
            this.form.date = this.adjustDateToLocalTime(value);
        },
        async fetchClasses() {
            try {
                const response = await api.get('/classes');
                this.classes = response.data;
            } catch (error) {
                this.$message.error('Failed to fetch classes');
            }
        },
        async fetchSections() {
            try {
                const response = await api.get('/sections', {
                    params: { class_id: this.form.class_id },
                });
                this.sections = response.data;
            } catch (error) {
                this.$message.error('Failed to fetch sections');
            }
        },
        async fetchStudents() {
            try {
                const response = await api.get('/students-by-class-and-section-filtering', {
                    params: { class_id: this.form.class_id, section_id: this.form.section_id },
                });

                this.form.students = response.data.data.map(student => ({
                    ...student,
                    attendance: '',
                }));
            } catch (error) {
                this.$message.error('Failed to fetch students');
            }
        },
        async fetchAttendances() {
            try {
                const dateToUse = this.selectedDate || this.formatDate(new Date());

                const response = await api.get('/attendances', {
                    params: { date: dateToUse },
                });

                this.attendances = response.data;
            } catch (error) {
                console.error('Error in fetchAttendances:', error);
                this.$message.error('Failed to fetch attendances');
            }
        },
        printAttendance() {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><title>Print Attendance</title>');
    printWindow.document.write('<style>');
    printWindow.document.write('body { font-family: Arial, sans-serif; margin: 20px; position: relative; }');
    printWindow.document.write('h1, h3 { text-align: center; }');
    printWindow.document.write('table { width: 100%; border-collapse: collapse; margin-top: 20px; }');
    printWindow.document.write('table, th, td { border: 1px solid black; }');
    printWindow.document.write('th, td { padding: 8px; text-align: left; }');
    printWindow.document.write('.header { display: flex; justify-content: space-between; align-items: center; }');
    printWindow.document.write('.logo { width: 200px; }');
    printWindow.document.write('.watermark { position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 0.1; z-index: -1; width: 300px; height: auto; }');
    printWindow.document.write('.qrcode { width: 100px; height: 100px; margin-top: 20px; }');
    printWindow.document.write('@media print { .page-break { page-break-before: always; } }');
    printWindow.document.write('</style>');
    printWindow.document.write('</head><body>');
    
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString();

    // Include the class and section information
    const classInfo = this.selectedAttendance.length > 0 ? this.selectedAttendance[0].class.name : '';
    const sectionInfo = this.selectedAttendance.length > 0 ? this.selectedAttendance[0].section.name : '';

    printWindow.document.write('<div class="header">');
    printWindow.document.write(`<img src="${logo}" class="logo" alt="School Logo">`);
    printWindow.document.write(`
        <div>
            Date: ${currentDate} ${currentTime}
            <h3>Attendance Report</h3>
            <p>Class: ${classInfo}</p>
            <p>Section: ${sectionInfo}</p>
        </div>`);
    printWindow.document.write('</div>');

    // Add watermark image
    printWindow.document.write(`<img src="${logoWater}" class="watermark" alt="Watermark">`);
    
    // Generate and add QR code for the attendance report
    const qrCodeUrl = `http://api.qrserver.com/v1/create-qr-code/?data=Attendance%20Report%0AClass:%20${classInfo}%0ASection:%20${sectionInfo}&size=100x100`;



    printWindow.document.write('<table>');
    printWindow.document.write('<thead><tr><th>Student Name</th><th>Attendance</th></tr></thead>');
    printWindow.document.write('<tbody>');
    
    this.selectedAttendance.forEach((attendance) => {
        printWindow.document.write('<tr>');
        printWindow.document.write(`<td>${attendance.student.name}</td>`);
        printWindow.document.write(`<td>${attendance.status}</td>`);
        printWindow.document.write('</tr>');
    });

    printWindow.document.write('</tbody></table>');
    printWindow.document.write(`<div class="qrcode"><img src="${qrCodeUrl}" alt="QR Code"></div>`);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
},
        async submitForm() {
            const payload = {
                date: this.form.date,
                class_id: this.form.class_id,
                section_id: this.form.section_id,
                attendances: this.form.students.map(student => ({
                    student_id: student.id,
                    status: student.attendance,
                })),
            };

            try {
                await api.post('/attendances', payload);
                this.$message.success('Attendance recorded successfully');
                this.dialogVisible = false;
                this.fetchAttendances(); // Refresh the attendance list after submitting
            } catch (error) {
                this.$message.error('Failed to record attendance');
            }
        },
        async fetchAvailableDates() {
            try {
                const response = await api.get('/attendance-dates');
                this.availableDates = response.data;
                // Set the selected date to today or the first available date
                this.selectedDate = this.availableDates.length > 0 ? this.availableDates[0] : this.formatDate(new Date());
                this.fetchAttendances();
            } catch (error) {
                this.$message.error('Failed to fetch available dates');
            }
        },
        async viewAttendance(row) {
            try {
                const response = await api.get(`/attendances/${row.class_id}/${row.section_id}`, {
                    params: { date: this.selectedDate || this.formatDate(new Date()) },
                });
                this.selectedAttendance = response.data;
                this.dialogViewAttendance = true;
            } catch (error) {
                this.$message.error('Failed to fetch attendance details');
            }
        },
    },
    mounted() {
        this.fetchClasses();
        this.fetchAvailableDates(); // Fetch the available dates on mount
    },
};
</script>
<style scoped>
/* Adjustments for the document icon button */
.el-button--success {
    margin-right: 8px;
}

/* Adjustments for the dropdown */
.el-select {
    margin-right: 8px;
}
</style>
