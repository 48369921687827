<template>
  <div>
    <el-input
      v-model="searchQuery"
      placeholder="Search by student name, parent, or receipt number"
      prefix-icon="el-icon-search"
      clearable
      @input="fetchFinancialDocuments"
    ></el-input>

    <el-table
      :data="financialDocuments"
      border
      style="width: 100%; margin-top: 20px"
    >
      <el-table-column prop="receipt_number" label="Receipt Number" width="180"></el-table-column>
      <el-table-column prop="student_name" label="Student Name" width="200"></el-table-column>
      <el-table-column prop="parent_name" label="Parent Name" width="200"></el-table-column>
      <el-table-column prop="total_amount" label="Total Amount" width="150"></el-table-column>
      <el-table-column prop="discount" label="Discount" width="150"></el-table-column>
      <el-table-column prop="final_amount" label="Final Amount" width="150"></el-table-column>
      <el-table-column label="Value Received" width="150">
        <template v-slot="scope">
          <div :style="getValueReceivedCellStyle(scope.row)">
            {{ scope.row.value_received }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="payment_method" label="Payment Method" width="150"></el-table-column>
      <el-table-column label="Actions" width="120">
        <template v-slot="scope">
          <el-button @click="printDocument(scope.row)">Print</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog
      :visible.sync="pdfDialogVisible"
      width="70%"
      :before-close="handlePdfDialogClose"
    >
      <iframe
        v-if="pdfUrl"
        :src="pdfUrl"
        width="100%"
        height="600px"
        style="border: none;"
      ></iframe>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pdfDialogVisible = false">Close</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { ElMessage } from 'element-plus';
import logo from "@/assets/img/logo-school.png";
import logoWater from '@/assets/img/logo-school-one.png';
import TajawalBold from "@/assets/fonts/Tajawal-Bold.ttf";
import AmiriRegular from "@/assets/fonts/Amiri-Regular.ttf";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import amiriFont from "@/assets/fonts/Amiri-Regular.ttf";  // Correct path to the font file
const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://sys.almasa-sch.ly/api',
});

export default {
  data() {
    return {
      searchQuery: '',
      financialDocuments: [],
      pdfDialogVisible: false,
      pdfUrl: null,
    };
  },
  methods: {
    fetchFinancialDocuments() {
      api.get('/financial-documents', {
        params: { search: this.searchQuery }
      })
      .then(response => {
        this.financialDocuments = response.data;
      })
      .catch(error => {
        console.error('Error fetching financial documents:', error);
      });
    },
    // printDocument(document) {
    //   // Generate PDF URL (implement this in your backend)
    //   api.post('/generate-pdf', { id: document.id })
    //     .then(response => {
    //       this.pdfUrl = response.data.pdfUrl;
    //       this.pdfDialogVisible = true;
    //     })
    //     .catch(error => {
    //       console.error('Error generating PDF:', error);
    //     });
    // },
    
printDocument(document) {
  const bankName = document.bank_name ? document.bank_name : "لا يوجد بيانات";
  const accountNumber = document.account_number ? document.account_number : "لا يوجد بيانات";
  const branchName = document.branch_name ? document.branch_name : "لا يوجد بيانات";
  const purpose = document.purpose ? document.purpose : "";

  const printContent = `
    <html>
    <head>
      <title>Print Document</title>
      <style>
        @font-face {
          font-family: 'TajawalBold';
          src: url('${TajawalBold}') format('truetype');
        }
        @font-face {
          font-family: 'PlaywritePE-Thin';
          src: url('${AmiriRegular}') format('truetype');
        }
        body { 
          font-family: 'PlaywritePE-Thin', Arial, sans-serif; 
          margin: 0;
          padding: 0;
          position: relative;
          direction: rtl;
          font-size: 10pt; /* Smaller font size for better fit */
        }
        .watermark {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0.1;
          font-family: 'PlaywritePE-Thin', Arial, sans-serif; 
          z-index: -1;
          width: 100%;
          height: 100%;
          background-image: url('${logoWater}');
          background-size: contain;
          background-repeat: no-repeat;
        }
        .receipt {
          margin: 10px; /* Reduced margin */
          padding: 5px; /* Reduced padding */
          border: 1px solid #000;
          width: 100%;
          box-sizing: border-box; /* Include padding and border in width */
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px; /* Reduced margin */
        }
        .header .logo {
          width: 80px; /* Reduced logo width */
        }
        .header .title {
          text-align: center;
          flex-grow: 1;
        }
        .header .school-name {
          text-align: left;
          font-family: 'TajawalBold', Arial, sans-serif;
          color: #161D6F;
          font-size: 14pt; /* Slightly smaller font size */
        }
        strong {
          background-color: #16325B; /* Background color for <strong> elements */
          color: white; /* Text color for <strong> elements */
          padding: 2px 4px; /* Padding for better readability */
          margin-bottom: 2px; /* Reduced margin */
          display: inline-block; /* Ensure margin affects spacing */
        }
        .info div {
          margin: 3px 0; /* Reduced margin */
        }
        .info span.value {
          background-color: #F8EDED; /* Background color for dynamic values */
          color: #201E43; /* Text color for values */
          padding: 2px 4px; /* Padding for better readability */
          display: inline-block; /* Ensure padding affects spacing */
          width: calc(100% - 20px); /* Adjusted width */
          text-align: center; /* Center-align text */
          box-sizing: border-box; /* Include padding in width calculation */
        }
        .qr-code {
          margin-top: 10px; /* Reduced margin */
          width: 80px; /* Reduced width */
          height: 80px; /* Reduced height */
          text-align: center;
        }
        .signature {
          margin-top: 10px; /* Reduced margin */
          text-align: left;
        }
        .footer {
          margin-top: 10px; /* Reduced margin */
          padding: 5px; /* Reduced padding */
          background-color: #f4f4f4;
          border-top: 1px solid #ddd;
          text-align: center;
        }
        .footer .invoice-number-inner {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .footer .inv-title-1 {
          font-weight: bold;
          margin-bottom: 5px;
        }
        .footer .invo-addr-1 {
          font-size: 0.8em; /* Smaller font size */
        }
        .footer img {
          vertical-align: middle;
        }
        @media print {
          .watermark {
            display: block;
          }
          .page-break {
            page-break-before: always;
          }
        }
      </style>
    </head>
    <body>
      <div class="watermark"></div>
      <div class="receipt">
        <!-- Header with logo, title, and school name -->
        <div class="header">
          <div class="school-name">
            مدرسة كاليدونيا الدولية
          </div>
          <div class="title">
            <strong>رقم الإيصال:</strong> ${document.receipt_number}
          </div>
          <div class="logo">
            <img src="${logo}" alt="Logo" width="80" />
          </div>
        </div>

        <!-- Receipt information -->
        <div class="info">
          <div><strong>طريقـــــــــــــــــــــــــــــــة الدفـــــــــع كانـــــــــــــــــت:</strong> <span class="value">${document.payment_method}</span></div>
          <div><strong>استـلمـت مـــــــــن الــــســيــــــــــــــــــــــــــد/ة:</strong> <span class="value">${document.parent_name}</span></div>
          <div><strong>مبـــــــــــــــــــــــــــــــــــلغــاً وقــــــــــــــــــــدره:</strong> <span class="value">${document.value_received}</span></div>
          <div><strong>المصــــــــــــــــــــــــــــــــــــــــرف:</strong> <span class="value">${bankName}</span></div>
          <div><strong>رقــــــــــــــــــــــــم الحســــــــــــــــــــــــــــــــــــــاب:</strong> <span class="value">${accountNumber}</span></div>
          <div><strong>الفـــــــــــــــــــــــــــــــرع:</strong> <span class="value">${branchName}</span></div>
          <div><strong>وذلك لغــــــــــــــــــــــــــــــــــــــــــــرض:</strong> <span class="value">${purpose}</span></div>
          <div><strong>القيمـــــــــــــــــــــــــــــــــــــــــة:</strong> <span class="value">${document.final_amount}</span></div>
        </div>

        <!-- QR Code -->
        <div class="qr-code">
          <img src="http://api.qrserver.com/v1/create-qr-code/?data=${document.receipt_number}&size=80x80" alt="QR Code" />
        </div>

        <!-- Signature section -->
        <div class="signature">
          <strong>التوقيـــع:</strong> ____________________
        </div>

        <!-- Footer section -->
        <div class="footer">
          <div class="invoice-number-inner">
            <h4 class="inv-title-1">Contact Us /</h4>
            <p class="invo-addr-1">
              <img style="width:17px; height:17px; margin-right:8px;" src="./location.png" /> Cordoba 2 - Hay Al Andalus - Behind Pasta Factory
              <br/>
              <img style="width:17px; height:17px; margin-right:8px;" src="./mail.png" /> info@almasa.ly <br/>
              <img style="width:17px; height:17px; margin-right:8px;" src="./phone-call.png" /> 0923290545 <br/>
            </p>
          </div>
        </div>
      </div>
    </body>
    </html>
  `;

  const printWindow = window.open('', '_blank', 'width=800,height=600');
  printWindow.document.open();
  printWindow.document.write(printContent);
  printWindow.document.close();

  // Wait for the document to load before calling print
  printWindow.onload = () => {
    printWindow.print();
    printWindow.close();
  };
},


    handlePdfDialogClose(done) {
      this.pdfDialogVisible = false;
      this.pdfUrl = null;
    },
    getValueReceivedCellStyle(document) {
      const valueReceived = parseFloat(document.value_received);
      const finalAmount = parseFloat(document.final_amount);

      if (valueReceived < finalAmount) {
        return 'background-color: red; color: white; padding: 5px;';
      } else if (valueReceived === finalAmount) {
        return 'background-color: green; color: white; padding: 5px;';
      } else {
        return 'padding: 5px;';
      }
    }
  },
  mounted() {
    this.fetchFinancialDocuments();
  }
};
</script>
